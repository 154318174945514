.content{
    background-color: #ccd8e4;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.logoContainer{
    padding: 10px;
}

.logo{
    height: unset;
    width: 100%;
}

.logoText{
    color: var(--highlight-color);
    display: grid;
    position: relative;
    font-size: larger;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1px;

    >p{
        font-size: 3rem;
        margin: 0;
        text-align: center;
    }

    >span{
        font-size: 1.4rem;
        text-align: right;
    }
}

.body{
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    > div {
        flex: 1;
    }

    > span{
        position: relative;
        height: fit-content;
        width: 100%;
        margin-bottom: 10px;

        > a {
            display: block;
            width: 100%;
            height: 2rem;
            text-align: center;
            line-height: 2rem;
            text-decoration: unset;

            &:hover{
                background-color: #a9b5c0;
            }
        }
    }
}