.masterPage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    overflow: hidden;
}

.masterShow {
    display: block;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.persistent,
.childPage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.childPage {
    display: none;
}

.childShow {
    display: block;
}

@keyframes fade {
    from {
        backdrop-filter: blur(0px);
        background-color: transparent;
        color: transparent;
    }
    to {
        color: #fff;
        backdrop-filter: blur(10px);
        background-color: #00000051;
    }
}

.content {
    position: absolute;
    top: var(--toolbarHeight);
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-rows: minmax(0, 1fr) calc((100vh - var(--toolbarHeight)) / 3);
    padding: 20px 60px;
    padding-top: 0;

    > div {
        position: relative;
    }
}

.wallpaper {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
}

.gridWallpaper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: rotate(5deg);
    filter: brightness(60%);

    > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px 0px;
        animation-name: sliderscroll;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-direction: alternate;
        animation-duration: 120s;
    }

    > div:last-child{
        animation-direction: alternate-reverse;
    }

    @keyframes sliderscroll{
        from{
            transform: translateX(calc(100vw - 50%));
        }
        to{
            transform: translateX(calc(-100vw + 50%));
        }
    }
}

.title {
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    min-height: 4rem;

    > img {
        max-width: 500px;
        max-height: 4rem;
        width: auto;
        height: auto;
    }
}
.subtitle {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    max-width: 70%;
    display: inline-block;
}

.supporter{
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% - 4rem);
    aspect-ratio: 1/1;

    > a {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    > img{
        width: 100%;
        max-height: auto;
    }

    >span{
        position: absolute;
        right: calc(100% + 4px);
        top: 0;
        white-space: nowrap;
        font-weight: bold;
    }
}

.textContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;

    direction: rtl;
}

.textContainerLeft {
    direction: ltr;
}

.textContainerCenter {
    display: flex;
    justify-content: center;
}

.textBox {
    padding: 1em 2em;
    border-radius: 1em;
    font-size: 2.5rem;
    max-width: 50%;
    direction: ltr;
    animation-name: fade;
    animation-timing-function: linear;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    a {
        color: inherit;
        text-decoration: inherit;
        font-weight: bold;
    }

    a:hover {
        text-decoration: underline;
    }
}

.play {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div {
        height: 65%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation-name: fade;
        animation-timing-function: linear;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }
}

.playIcon {
    font-size: 6rem;
}
