.container{
    display: inline;
    width: fit-content;
    padding: 18px 35px;
    border-radius: 6px;
    background-color: #00000051;
    backdrop-filter: blur(10px);
    font-weight: bold;
    cursor: pointer;
    position: relative;

    >span{
        font-size: 1.3rem;
    }

    >div{
        top: 100%;
        left: 0;
        position: absolute;
        width: 100%;
        height: calc(100vh - var(--toolbarHeight));
        overflow: hidden;
        pointer-events: none;

        >ul{
            pointer-events: all;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 0px;
            transition: 0.5s;
            max-height: 100%;
            overflow: auto;
            background-color: #000000ab;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            overflow-x: hidden;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            >li{
                text-align: center;
                position: relative;

                &::after{
                    content: "";
                    height: 1px;
                    background-color: #ffffff;
                    left: 20px;
                    right: 20px;
                    bottom: 0;
                    position: absolute;
                    display: block;
                }
            }
        }
    }

    &:hover{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        > div > ul{
            height: 100%;
        }
    }
}