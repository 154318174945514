.mediumImg {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > img {
        height: 100%;
        max-width: auto;
    }
}

.productImg {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
        height: 100%;
        max-width: 10rem;
        display: flex;
        flex-direction: column;

        > div {
            max-height: 8rem;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            > img {
                max-height: 100%;
                width: auto;
                max-width: 100%;
            }
        }


        > span {
            text-align: center;
        }
    }

    
}

.award {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;

    > img {
        height: 100%;
        max-width: auto;
        background-color: #fff;
    }
}

.center {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;

    > img {
        height: 100%;
        max-width: auto;
        background-color: #fff;
    }
}

