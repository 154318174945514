.page{
    overflow: hidden;
    svg {
        fill: #fff;
    }

    >div{
        overflow: hidden;
    }
}

.container{
    position: relative;
    width: 100%;
    height: 100%;
}

.iframe{
    width: 100%;
    height: 100%;
}

.video{
    width: 100%;
    height: 100%;
    display: none;
}

.show{
    display: block;
}