.logo {
    height: 100%;
    aspect-ratio: 1/1;
    display: block;

    >div {
        height: 100%;
        aspect-ratio: 1/1;
        padding: 0!important;
        --padding: 0px!important;
        --total-size: 100%!important;
    
        >#infokom-logo-svg > #infokom-logo-circle-mask {
            border-radius: 50%!important;
        }
    }
}

.staticLogo{
    height: 100%;
    aspect-ratio: 1/1;
    display: block;

    > img{
        height: 100%;
        max-width: auto;
        pointer-events: none;
    }
}