.page{
    > div{
        overflow: hidden;
    }
}

.toolbar {
    height: var(--toolbarHeight);
    background-color: transparent;
    background-image: none;
    overflow: visible;
}

.toolbarLeft, .toolbarRight {
    width: fit-contentys;
}

.toolbarCenter {
    flex: 1;
}

.logo {
    margin: 10px;
    height: calc(100% - 20px);
}

.dropdownButton{
    position: relative;
    top: 10px;
    margin: 20px 10px;
}

.pages{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}